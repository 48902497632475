import React, { useState, useEffect } from 'react';
import { useHost } from '../../contexts/HostContext';
import { Modal, Box, Typography, TextField, Button, MenuItem, Switch, FormControlLabel, Alert, IconButton, Grid, Paper, Divider } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';
import { countries } from 'countries-list';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 700, // Adjust the max-width for better UI
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const categories = [
  "Host", "Helper", "Visitor", "Government Official", "Dependent",
  "Projects", "Soneva Fushi", "Soneva Jani", "Soneva Kiri", "HUB",
  "Supplier", "Service Provider"
];

const countryList = Object.values(countries).map(country => country.name);

const AddHostModal = ({ open, onClose, currentHost }) => {
  const { addHost, updateHost } = useHost();
  const [hostDetails, setHostDetails] = useState({
    name: '',
    category: '',
    idNo: '',
    position: '',
    nidPassportNumber: '',
    wpVisaNumber: '',
    gender: '',
    country: '',
    status: 'Active',
    profilePicture: null,
    files: [],
    house: { name: '', color: '#000000' },
    customFields: []
  });
  const [error, setError] = useState('');

  useEffect(() => {
    if (currentHost) {
      setHostDetails({
        ...currentHost,
        profilePicture: null, // Do not preload profile picture for edit, unless explicitly changed
        files: currentHost.files || [],
        house: currentHost.house || { name: '', color: '#000000' },
        customFields: currentHost.customFields || []
      });
    } else {
      setHostDetails({
        name: '',
        category: '',
        idNo: '',
        position: '',
        nidPassportNumber: '',
        wpVisaNumber: '',
        gender: '',
        country: '',
        status: 'Active',
        profilePicture: null,
        files: [],
        house: { name: '', color: '#000000' },
        customFields: []
      });
    }
  }, [currentHost]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHostDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleHouseChange = (key, value) => {
    setHostDetails(prevState => ({
      ...prevState,
      house: { ...prevState.house, [key]: value }
    }));
  };

  const handleAddField = () => {
    setHostDetails(prevState => ({
      ...prevState,
      customFields: [...prevState.customFields, { name: '', value: '' }]
    }));
  };

  const handleFieldChange = (index, key, value) => {
    const updatedFields = [...hostDetails.customFields];
    updatedFields[index][key] = value;
    setHostDetails(prevState => ({
      ...prevState,
      customFields: updatedFields
    }));
  };

  const handleRemoveField = (index) => {
    const updatedFields = hostDetails.customFields.filter((_, i) => i !== index);
    setHostDetails(prevState => ({
      ...prevState,
      customFields: updatedFields
    }));
  };

  const handleStatusChange = (e) => {
    setHostDetails(prevState => ({
      ...prevState,
      status: e.target.checked ? 'Active' : 'Inactive'
    }));
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setHostDetails(prevState => ({
        ...prevState,
        profilePicture: file
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updateData = { ...hostDetails };

      // If profilePicture is null, it means the user did not select a new picture, so don't update it
      if (!hostDetails.profilePicture) {
        delete updateData.profilePicture; // Prevent profile picture from being sent as `null`
      }

      if (currentHost) {
        await updateHost(currentHost.id, updateData);
      } else {
        await addHost(updateData);
      }
      onClose();
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} component="form" onSubmit={handleSubmit}>
        <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ marginBottom: 2, fontWeight: 'bold', textAlign: 'center' }}>
          {currentHost ? 'Edit Host' : 'Add New Host'}
        </Typography>
        {error && <Alert severity="error" sx={{ marginBottom: 2 }}>{error}</Alert>}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Name"
              fullWidth
              margin="normal"
              name="name"
              value={hostDetails.name}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              select
              label="Category"
              fullWidth
              margin="normal"
              name="category"
              value={hostDetails.category}
              onChange={handleInputChange}
              required
            >
              {categories.map((category, index) => (
                <MenuItem key={index} value={category}>
                  {category}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="ID No."
              fullWidth
              margin="normal"
              name="idNo"
              value={hostDetails.idNo}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Position"
              fullWidth
              margin="normal"
              name="position"
              value={hostDetails.position}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="NID/Passport Number"
              fullWidth
              margin="normal"
              name="nidPassportNumber"
              value={hostDetails.nidPassportNumber}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="WP/VISA Number"
              fullWidth
              margin="normal"
              name="wpVisaNumber"
              value={hostDetails.wpVisaNumber}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Gender"
              fullWidth
              margin="normal"
              name="gender"
              value={hostDetails.gender}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              select
              label="Country"
              fullWidth
              margin="normal"
              name="country"
              value={hostDetails.country?.toLowerCase()} // Normalize the case for comparison
              onChange={handleInputChange}
            >
              {countryList.map((country, index) => (
                <MenuItem key={index} value={country.toLowerCase()}>
                  {country}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="House Name"
              fullWidth
              margin="normal"
              name="houseName"
              value={hostDetails.house.name}
              onChange={(e) => handleHouseChange('name', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="House Color"
              type="color"
              fullWidth
              margin="normal"
              name="houseColor"
              value={hostDetails.house.color}
              onChange={(e) => handleHouseChange('color', e.target.value)}
            />
          </Grid>
        </Grid>
        <Divider sx={{ marginY: 2 }} />
        <Typography variant="h6" sx={{ marginBottom: 1 }}>Custom Fields</Typography>
        {hostDetails.customFields.map((field, index) => (
          <Grid container spacing={2} key={index} alignItems="center" sx={{ marginBottom: 1 }}>
            <Grid item xs={5}>
              <TextField
                label="Field Name"
                fullWidth
                value={field.name}
                onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label="Field Value"
                fullWidth
                value={field.value}
                onChange={(e) => handleFieldChange(index, 'value', e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={() => handleRemoveField(index)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleAddField}
          sx={{ marginTop: 1 }}
        >
          Add Custom Field
        </Button>
        <FormControlLabel
          control={
            <Switch
              checked={hostDetails.status === 'Active'}
              onChange={handleStatusChange}
              name="status"
            />
          }
          label="Active"
          sx={{ marginTop: 2 }}
        />
        <Button
          variant="contained"
          component="label"
          startIcon={<CloudUploadIcon />}
          fullWidth
          sx={{ marginTop: 2 }}
        >
          Upload Profile Picture
          <input
            type="file"
            hidden
            onChange={handleProfilePictureChange}
          />
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: 3, bgcolor: 'primary.main', color: 'white', fontWeight: 'bold' }}
        >
          {currentHost ? 'Update Host' : 'Add Host'}
        </Button>
      </Box>
    </Modal>
  );
};

export default AddHostModal;
